import { template as template_18d73ebc5f0b4fd3b1f6590eb977f096 } from "@ember/template-compiler";
const SidebarSectionMessage = template_18d73ebc5f0b4fd3b1f6590eb977f096(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
