import { template as template_bad86aad11ba4bf88015b4c07269a067 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bad86aad11ba4bf88015b4c07269a067(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
