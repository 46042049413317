import { template as template_717ab7d11ab040ddb1e8ac7c0d9d9cad } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_717ab7d11ab040ddb1e8ac7c0d9d9cad(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
