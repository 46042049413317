import { template as template_dbafefc38897404c90ed1f93b9edf0f0 } from "@ember/template-compiler";
const FKText = template_dbafefc38897404c90ed1f93b9edf0f0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
