import { template as template_3871f41309084e17be2172dbcc3a5f7e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_3871f41309084e17be2172dbcc3a5f7e(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
